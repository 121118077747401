<template>
  <PVDialog
    :visible="visible"
    :modal="true"
    :dismissable-mask="true"
    class="transition-all duration-500"
    :style="{ width: '400px' }"
    :closable="false"
    :draggable="false">
    <template #header>
      <p class="text-secondary-800 font-semibold">הגשת תרגיל</p>
    </template>
    <Transition name="slide-fade">
      <div class="flex flex-col gap-1 text-secondary-900 text-sm">
        <span>האם להגיש את התרגיל?</span>
        <div class="flex gap-1">
          <span>בלחיצה על הבא,</span>
          <span class="font-simplerBold">התרגיל יוגש מחדש למורה</span>
        </div>
        <span>
          (תרגילים המבוססים על התשובות החדשות יתאפסו ויש להגיש גם אותם מחדש)
        </span>
      </div>
    </Transition>

    <template #footer>
      <PVButton label="חזרה לתרגיל" class="cancelButton" />
      <PVButton
        label="הגשה"
        severity="secondary"
        class="confirmButton border-1 border-primary !text-primary hover:bg-[#F3FBFF]"/>
    </template>
  </PVDialog>
</template>

<script setup lang="ts">
import PVDialog from 'primevue/dialog';
import PVButton from 'primevue/button';

const visible = defineModel<boolean>({ default: false });
</script>
