<template>
  <div
    class="fixed top-9 z-[10] w-30"
    :class="{ 'left-14': rtl, 'right-14': !rtl }">
    <div
      class="w-30 h-9 bg-white flex items-center gap-2 rounded-br-sm rounded-bl-sm border-x border-b border-secondary-300 cursor-pointer"
      @click="togglePanel">
      <div class="h-full flex justify-center items-center bg-primary-200 p-2">
        <img :src="TeamUsersIcon" alt="team" />
      </div>
      <span class="text-xs">קבוצתי {{ `(${team.length})` }}</span>
    </div>
    <OverlayPanel ref="teamPanel">
      <div dir="ltr" class="w-[160px] h-[168px] m-4 overflow-y-auto">
        <div dir="rtl" class="flex flex-col gap-2 px-3">
          <div
            v-for="student in team"
            :key="student.id"
            class="flex gap-2 items-center">
            <img
              :src="student.avatar || defaultAvatarImage"
              :alt="student.fullName"
              class="h-5 w-5 rounded-full"/>
            <span>{{ student.fullName }}</span>
          </div>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type { PropType } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import TeamUsersIcon from '/@/assets/teamUsers.svg?url';
import { defaultAvatarImage } from '@/utils/helpers';
import { IStudent } from '/@/types/interfaces';

defineProps({
  team: {
    type: Array,
    default: [] as PropType<IStudent[]>,
  },
  rtl: {
    type: Boolean,
    default: true,
  },
});

const teamPanel = ref();

const togglePanel = event => {
  teamPanel.value.toggle(event);
};
</script>
